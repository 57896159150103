/**
 * Acc지원 정보 - 보유 허가 및 등록 현황 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
 */
export default class AccLicenseInfoDto {
  constructor(obj) {
    this.acc_license_no = obj.acc_license_no;
    this.acc_no = obj.acc_no;
    this.license_name = obj.license_name;
    this.license_no = obj.license_no;
    this.license_date = obj.license_date;
    this.display_order = obj.display_order || -1;
  }
}
