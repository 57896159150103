/**
 * Acc지원 정보 - 보유 지적 재산권 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
 */
export default class AccPropertyInfoDto {
  constructor(obj) {
    this.acc_property_no = obj.acc_property_no;
    this.acc_no = obj.acc_no;
    this.property_name = obj.property_name;
    this.property_no = obj.property_no;
    this.reg_app_code = obj.reg_app_code;
    this.reg_app_date = obj.reg_app_date;
    this.display_order = obj.display_order || -1;
  }
}
