<template>
  <div>
    <BaseLabelElement
      v-if="label"
      :label="label"
      :id="id"
      :required="required"
    />
    <BaseInputElement
      :id="id"
      type="number"
      v-bind="$attrs"
      :value="value"
      @input="onInput"
      :inputOptions="{ raw: raw, positiveOnly: positiveOnly }"
      :invalid="invalid"
    />
  </div>
</template>

<script>
import BaseInput from './BaseInput.vue';

/**
 * 숫자 입력용 Input 컴포넌트
 */
export default {
  name: 'NumberInput',
  extends: BaseInput, // (확장) 기본 Input 컴포넌트
  props: {
    /**
     * 원래 입력값(타입) 유지 여부
     */
    raw: {
      type: Boolean,
      default: true,
    },
    /**
     * 양수만 가능 여부
     */
    positiveOnly: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<NumberInput value="123456" placeholder="양수만 입력 가능"/>

<NumberInput value="-123456" :positiveOnly="false" />

```

레이블 사용 예시 :
```jsx
<NumberInput label="수량" value="10" required />
```
</docs>
