/**
 * Acc지원 정보 - 수상경력 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
 */
export default class AccAwardCareerInfoDto {
  constructor(obj) {
    this.acc_award_career_no = obj.acc_award_career_no;
    this.acc_no = obj.acc_no;
    this.award_date = obj.award_date;
    this.competition_name = obj.competition_name;
    this.host_organization = obj.host_organization;
    this.award = obj.award;
    this.display_order = obj.display_order || -1;
  }
}
