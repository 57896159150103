<template>
  <div
    v-if="accInfo"
    class="form-container">
    <ValidationObserver ref="form">
      <!-- 회사정보 입력 폼 -->
      <form>
        <fieldset>
          <legend>{{$t('content.accelerating.academy.Common.txt02')}}</legend>
          <div class="align-right">
            <!-- *는 필수입력 항목입니다. -->
            <em class="required-mark">*</em>{{ $t('content.networking.CompanyInfo.txt53') }}
          </div>
          <ul>
            <!-- 업체명 -->
            <li
              v-if="accApplyPgmMngDetail.companyNameYn || accApplyPgmMngDetail.companyNameEnYn"
              class="form-row">
              <ValidationProvider
                vid="001_company_name"
                :name="$t('content.accelerating.academy.CompanyInfo.txt01')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <BaseLabelElement
                  id="company_name"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt01')"
                  required
                />

                <!-- 업체명 국문 -->
                <BaseInputElement
                  ref="001_company_name"
                  v-if="accApplyPgmMngDetail.companyNameYn"
                  v-model="accInfo.company_name"
                  width="half"
                  id="company_name"
                  :placeholder="$t('content.accelerating.academy.CompanyInfo.txt02')"
                  maxlength="100"
                  required
                  :invalid="failed"
                />

                <!-- 업체명 영문 -->
                <BaseInputElement
                  v-if="accApplyPgmMngDetail.companyNameEnYn"
                  v-model="accInfo.company_name_en"
                  width="half"
                  id="company_name_en"
                  :placeholder="$t('content.accelerating.academy.CompanyInfo.txt03')"
                  maxlength="100"
                />

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>
            <!-- // 업체명 -->

            <!-- 사업자등록번호 -->
            <li
              v-if="accApplyPgmMngDetail.companyRegNoYn"
              class="form-row">
              <NumberInput
                v-model="accInfo.company_reg_no"
                id="company_reg_no"
                :label="$t('content.accelerating.academy.CompanyInfo.txt04')"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt05')"
                maxlength="10"
              />
            </li>

            <!-- 법인 등록번호 -->
            <li
              v-if="accApplyPgmMngDetail.corporationRegNoYn"
              class="form-row">
              <NumberInput
                v-model="accInfo.corporation_reg_no"
                id="corporation_reg_no"
                :label="$t('content.accelerating.academy.CompanyInfo.txt06')"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt07')"
                maxlength="15"
              />
            </li>

            <!-- 벤처기업 인증번호 -->
            <li
              v-if="accApplyPgmMngDetail.ventureCertNoYn"
              class="form-row">
              <NumberInput
                v-model="accInfo.venture_cert_no"
                id="venture_cert_no"
                :label="$t('content.accelerating.academy.CompanyInfo.txt08')"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt09')"
                maxlength="30"
              />
            </li>

            <!-- 설립연도 -->
            <li
              v-if="accApplyPgmMngDetail.buildYearYn"
              class="form-row">
              <ValidationProvider
                vid="002_build_date"
                :name="$t('content.accelerating.academy.CompanyInfo.txt10')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <BaseLabelElement
                  id="build_date"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt10')"
                  required
                />
                <TypeableDatepicker
                  ref="002_build_date"
                  v-model="buildDate"
                  id="build_date"
                  placeholder="YYYY-MM-DD"
                  initialView="year"
                  :invalid="failed"
                />

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 대표자 -->
            <li
              v-if="accApplyPgmMngDetail.ceoNameYn"
              class="form-row">
              <ValidationProvider
                vid="003_ceo_name"
                :name="$t('content.accelerating.academy.CompanyInfo.txt14')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <BaseInput
                  ref="003_ceo_name"
                  id="ceo_name"
                  v-model="accCeoInfo[0].ceo_name"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt14')"
                  :placeholder="$t('content.accelerating.academy.CompanyInfo.txt15')"
                  required
                  :invalid="failed"
                />

                <!-- 대표자는 3명까지 입력 가능합니다. -->
                <span class="input-desc">
                  {{ $t('content.accelerating.academy.CompanyInfo.txt18', [LIMIT_CEO_INFO]) }}
                </span>
                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- 대표자 - 추가 입력 정보 (공동대표) -->
              <ul
                v-if="accCeoInfo.length > 1"
                class="additional-info-wrap">
                <template
                  v-for="(additionalCeoInfo, index) in accCeoInfo"
                >
                  <li
                    v-if="index > 0"
                    :key="index"
                    class="additional-info">
                    <BaseLabelElement
                      :id="'ceo_name' + index"
                      :label="$t('content.accelerating.academy.CompanyInfo.txt16')"
                      class="input-title"
                    />
                    <!-- 공동대표 - 추가 입력 정보 -->
                    <BaseInputElement
                      :id="'ceo_name' + index"
                      v-model="additionalCeoInfo.ceo_name"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt17')"
                    />

                    <!-- 삭제 버튼 -->
                    <BaseButton
                      type="line"
                      size="small"
                      @click="deleteCeoInfo(index)"
                    >{{$t('content.common.button.btn07')}}</BaseButton>
                  </li>
                </template>
              </ul>

              <div
                v-if="accCeoInfo.length < LIMIT_CEO_INFO"
                class="btn-wrap">
                <!-- 추가 버튼 -->
                <BaseButton
                  type="line"
                  size="medium"
                  @click="addCeoInfo"
                >{{$t('content.common.button.btn18')}}</BaseButton>
              </div>
              <!-- // 대표자 - 추가 입력 정보 (공동대표) -->
            </li>
            <!-- // 대표자 -->

            <!-- 대표자 연락처 -->
            <li
              v-if="accApplyPgmMngDetail.ceoCellphoneYn"
              class="form-row">
              <ValidationProvider
                vid="004_ceo_cellphone"
                :name="$t('content.accelerating.academy.Common.txt10')"
                :rules="{ required: true, phone: true }"
                v-slot="{ errors, failed }">
                <TelInput
                  ref="004_ceo_cellphone"
                  id="ceo_cellphone"
                  v-model="ceoCellphone"
                  :label="$t('content.accelerating.academy.Common.txt10')"
                  :placeholder="$t('content.accelerating.academy.CompanyInfo.txt54')"
                  required
                  :invalid="failed"
                  maxlength="13"
                />

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 대표자 이메일 -->
            <li
              v-if="accApplyPgmMngDetail.ceoEmailYn"
              class="form-row">
              <ValidationProvider
                vid="005_ceo_email"
                :name="$t('content.accelerating.academy.CompanyInfo.txt50')"
                :rules="{ required: true, email: true }"
                v-slot="{ errors, failed }">
                <BaseInput
                  ref="005_ceo_email"
                  id="ceo_email"
                  v-model="accInfo.ceo_email"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt50')"
                  :placeholder="$t('content.accelerating.academy.CompanyInfo.txt50_1')"
                  maxlength="50"
                  required
                  :invalid="failed"
                />

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 대표자 최종학력 -->
            <li
              v-if="accApplyPgmMngDetail.ceoFinalEnducationYn"
              class="form-row">
              <ValidationProvider
                vid="006_ceo_final_enducation"
                :name="$t('content.accelerating.academy.CompanyInfo.txt51')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <BaseInput
                  ref="006_ceo_final_enducation"
                  id="ceo_final_enducation"
                  v-model="accInfo.ceo_final_enducation"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt51')"
                  :placeholder="$t('content.accelerating.academy.CompanyInfo.txt51_1')"
                  maxlength="50"
                  required
                  :invalid="failed"
                />

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 대표자 주요이력 -->
            <li
              v-if="accApplyPgmMngDetail.ceoMajorCareerYn"
              class="form-row">
              <ValidationProvider
                vid="007_ceo_major_career"
                :name="$t('content.accelerating.academy.CompanyInfo.txt52')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <BaseInput
                  ref="007_ceo_major_career"
                  id="ceo_major_career"
                  v-model="accInfo.ceo_major_career"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt52')"
                  :placeholder="$t('content.accelerating.academy.CompanyInfo.txt52_1')"
                  maxlength="50"
                  required
                  :invalid="failed"
                />

                <!-- 50자 까지 입력 가능합니다. -->
                <span class="input-desc">{{ $t('content.accelerating.academy.CompanyInfo.txt53', [50]) }}</span>
                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>
            <!-- 미국법인설립 -->
            <li
              v-if="accApplyPgmMngDetail.usCorpEstYn"
              class="form-row">
              <ValidationProvider
                vid="012_us_corp_est_yn"
                :name="$t('content.accelerating.academy.CompanyInfo.txt55')"
                >
                <BaseLabelElement
                  type="inline"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt55')"
                />
                <!-- 개인 -->
                <BaseRadioButton
                  ref="012_us_corp_est_yn"
                  name="us_corp_est_yn"
                  v-model="accInfo.us_corp_est_yn"
                  value="Y"
                  required
                >{{$t('content.accelerating.academy.CompanyInfo.txt56')}}</BaseRadioButton>
                <!-- 팀 -->
                <BaseRadioButton
                  name="us_corp_est_yn"
                  v-model="accInfo.us_corp_est_yn"
                  value="N"
                  required
                >{{$t('content.accelerating.academy.CompanyInfo.txt57')}}</BaseRadioButton>
              </ValidationProvider>
            </li>
            <!-- // 미국법인설립 -->
            <!-- 현재 직원 수 -->
            <li
              v-if="accApplyPgmMngDetail.employeeNumberYn"
              class="form-row">
              <ValidationProvider
                vid="008_employee_number"
                :name="$t('content.accelerating.academy.CompanyInfo.txt19')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <NumberInput
                  ref="008_employee_number"
                  id="employee_number"
                  v-model="accInfo.employee_number"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt19')"
                  :placeholder="$t('content.accelerating.academy.CompanyInfo.txt20')"
                  maxlength="10"
                  required
                  :invalid="failed"
                />

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 직원추가계획 -->
            <li
              v-if="accApplyPgmMngDetail.employeeAddTagYn"
              class="form-row">
              <ValidationProvider
                vid="009_employee_add_tag"
                :name="$t('content.accelerating.academy.CompanyInfo.txt21')"
                :rules="{ required: true }"
                v-slot="{ errors, failed }">
                <NumberInput
                  ref="009_employee_add_tag"
                  id="employee_add_tag"
                  v-model="accInfo.employee_add_tag"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt21')"
                  :placeholder="$t('content.accelerating.academy.CompanyInfo.txt22')"
                  maxlength="10"
                  required
                  :invalid="failed"
                />

                <!-- 입주 후 6개월 이내에 예상되는 추가 인력을 입력해 주세요. -->
                <span class="input-desc">{{$t('content.accelerating.academy.CompanyInfo.txt23')}}</span>
                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>

            <!-- 신청구분 -->
            <li
              v-if="accApplyPgmMngDetail.applicationCategoryYn"
              class="form-row">
              <ValidationProvider
                vid="010_application_category"
                :name="$t('content.accelerating.academy.CompanyInfo.txt43')"
                :rules="{ required: true }"
                v-slot="{ errors }">
                <BaseLabelElement
                  type="inline"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt43')"
                  required
                />
                <!-- 개인사업자 -->
                <BaseRadioButton
                  ref="010_application_category"
                  name="application_category"
                  v-model="accInfo.application_category"
                  value="0"
                  required
                >{{$t('content.accelerating.academy.CompanyInfo.txt45')}}</BaseRadioButton>
                <!-- 예비창업자 -->
                <BaseRadioButton
                  name="application_category"
                  v-model="accInfo.application_category"
                  value="1"
                  required
                >{{$t('content.accelerating.academy.CompanyInfo.txt46')}}</BaseRadioButton>

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>
            <!-- // 신청구분 -->
            <li
              v-if="accApplyPgmMngDetail.memberConfigurationYn && accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1">
              <!-- 개발자 -->
              <ValidationProvider
                vid="011_member_configuration"
                :name="$t('content.accelerating.academy.CompanyInfo.txt44')"
                rules="required|check_boxs:하나 이상 선택 하세요.|check_member_count:해커톤 지원은 4인으로 가능합니다."
                v-slot="{ errors }">
                <BaseLabelElement
                  type="inline"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt44')"
                  required
                />
                <BaseCheckbox
                    id="checkbox0"
                    class="hidden"
                    type="inline"
                    ref="checkbox1"
                    v-model="checkAll"
                >{{$t('content.accelerating.academy.MemberInfo.txt24')}}</BaseCheckbox>
                <!-- 개발자 -->
                <div>
                  <BaseCheckbox
                    id="checkbox1"
                    type="inline"
                    ref="checkbox1"
                    v-model="checkMember1"
                  >{{$t('content.accelerating.academy.MemberInfo.txt24')}}</BaseCheckbox>
                  <div class="input-container">
                    <ValidationProvider
                      rules="check_amount:1, 한명 이상 선택하세요."
                      :name="$t('content.accelerating.academy.MemberInfo.txt24') + ` 인원`"
                      v-slot="{ errors }"
                    >
                      <NumberInput
                        class="hidden"
                        v-model="accInfo.NUMBER_DEVELOPER"
                        :name="$t('content.accelerating.academy.MemberInfo.txt24')"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt24')"
                        maxlength="10"
                        :disabled="!accInfo.check_developer"
                      />
                      <select
                        id="select1"
                        v-model="accInfo.NUMBER_DEVELOPER"
                        class="input-border"
                        :disabled="!accInfo.check_developer"
                        value="0"
                        >
                        <option v-for="option in memberCount" :value="option.value"
                          v-bind:key="option.text">
                          {{ option.text }}
                        </option>
                      </select>
                      <span
                        v-if="accInfo.check_developer"
                        class="input-error-desc">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- 기획자 -->
                <div>
                  <BaseCheckbox
                    id="checkbox2"
                    ref="checkbox2"
                    v-model="checkMember2"
                  >{{$t('content.accelerating.academy.MemberInfo.txt25')}}</BaseCheckbox>
                  <div class="input-container">
                    <ValidationProvider
                      rules="check_amount:2, 한명 이상 선택하세요."
                      :name="$t('content.accelerating.academy.MemberInfo.txt25') + ` 인원`"
                      v-slot="{ failed, errors }"
                    >
                      <NumberInput
                        class="hidden"
                        v-model="accInfo.NUMBER_DIRECTOR"
                        :name="$t('content.accelerating.academy.MemberInfo.txt25')"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt25')"
                        maxlength="10"
                        :disabled="!accInfo.check_director"
                        :invalid="failed"
                      />
                      <select
                        id="select2"
                        v-model="accInfo.NUMBER_DIRECTOR"
                        class="input-border"
                        :disabled="!accInfo.check_director"
                        >
                        <option v-for="option in memberCount" :value="option.value"
                          v-bind:key="option.text">
                          {{ option.text }}
                        </option>
                      </select>
                      <span
                        v-if="accInfo.check_director"
                        class="input-error-desc">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- 디자이너 -->
                <div>
                  <BaseCheckbox
                    id="checkbox3"
                    ref="checkbox3"
                    v-model="checkMember3"
                  >{{$t('content.accelerating.academy.MemberInfo.txt26')}}</BaseCheckbox>
                  <div class="input-container">
                    <ValidationProvider
                      rules="check_amount:3, 한명 이상 선택하세요."
                      :name="$t('content.accelerating.academy.MemberInfo.txt26') + ` 인원`"
                      v-slot="{ failed, errors }"
                    >
                      <NumberInput
                        class="hidden"
                        v-model="accInfo.NUMBER_DESIGNER"
                        :name="$t('content.accelerating.academy.MemberInfo.txt26')"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt26')"
                        maxlength="10"
                        :disabled="!accInfo.check_designer"
                        :invalid="failed"
                      />
                      <select
                        id="select3"
                        v-model="accInfo.NUMBER_DESIGNER"
                        class="input-border"
                        :disabled="!accInfo.check_designer"
                        >
                        <option v-for="option in memberCount" :value="option.value"
                          v-bind:key="option.text">
                          {{ option.text }}
                        </option>
                      </select>
                      <span
                        v-if="accInfo.check_designer"
                        class="input-error-desc">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>
            <!-- 멤버구성 -->
            <li
              v-if="accApplyPgmMngDetail.memberConfigurationYn && accApplyPgmMngDetail.accName.indexOf('해커톤') === -1"
              class="form-row">
              <ValidationProvider
                vid="011_member_configuration"
                :name="$t('content.accelerating.academy.CompanyInfo.txt44')"
                :rules="{ required: true }"
                v-slot="{ errors }">
                <BaseLabelElement
                  type="inline"
                  :label="$t('content.accelerating.academy.CompanyInfo.txt44')"
                  required
                />
                <!-- 개인 -->
                <BaseRadioButton
                  ref="011_member_configuration"
                  name="member_configuration"
                  v-model="accInfo.member_configuration"
                  value="0"
                  required
                >{{$t('content.accelerating.academy.CompanyInfo.txt47')}}</BaseRadioButton>
                <!-- 팀 -->
                <BaseRadioButton
                  name="member_configuration"
                  v-model="accInfo.member_configuration"
                  value="1"
                  required
                >{{$t('content.accelerating.academy.CompanyInfo.txt48')}}</BaseRadioButton>

                <!-- 팀 인원 : 멤버구성('011_member_configuration')이 팀('1')인 경우 필수 -->
                <div class="inline">
                  <ValidationProvider
                    vid="011_member_configuration_input"
                    :name="$t('content.accelerating.academy.CompanyInfo.txt44')"
                    :rules="{ requiredIf: { target: '011_member_configuration', values: '1' } }"
                    v-slot="{ failed }"
                  >
                      <NumberInput
                        ref="011_member_configuration_input"
                        v-model="accInfo.member_configuration_input"
                        :placeholder="$t('content.accelerating.academy.CompanyInfo.txt49')"
                        maxlength="10"
                        :disabled="accInfo.member_configuration !== '1'"
                        :invalid="failed"
                      />
                  </ValidationProvider>
                </div>

                <span class="input-error-desc">{{ errors[0] }}</span>
              </ValidationProvider>
            </li>
            <!-- // 멤버구성 -->

            <!-- 홈페이지 / SNS -->
            <li
              v-if="accApplyPgmMngDetail.companyHomepageUrlYn"
              class="form-row">
              <BaseLabelElement
                :label="$t('content.accelerating.academy.CompanyInfo.txt24')"
                id="company_homepage_url"
              />

              <!-- 홈페이지 -->
              <BaseInputElement
                icon="homepage"
                v-model="accInfo.company_homepage_url"
                id="company_homepage_url"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt25')"
                maxlength="255"
              />

              <!-- SNS -->
              <BaseInputElement
                icon="sns"
                v-model="accInfo.company_insta_url"
                id="company_insta_url"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt26')"
                maxlength="255"
              />
            </li>

            <!-- 수상경력 -->
            <li
              v-if="accApplyPgmMngDetail.accAwardCareerInfoYn"
              class="form-row">
              <BaseLabelElement
                :label="$t('content.accelerating.academy.CompanyInfo.txt28')"
              />
              <!-- 날짜 -->
              <TypeableDatepicker
                v-model="accAwardCareerInfo[0].award_date"
                width="half"
                id="award_date"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt29') + ' (YYYY-MM-DD)'"
                initialView="year"
              />
              <!-- 대회명 -->
              <BaseInputElement
                v-model="accAwardCareerInfo[0].competition_name"
                width="half"
                id="competition_name"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt30')"
              />
              <!-- 주최기관 -->
              <BaseInputElement
                v-model="accAwardCareerInfo[0].host_organization"
                width="half"
                id="host_organization"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt31')"
              />
              <!-- 수상내역 -->
              <BaseInputElement
                v-model="accAwardCareerInfo[0].award"
                width="half"
                id="award"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt32')"
              />

              <!-- 최근 수상경력부터 입력해 주세요. -->
              <span class="input-desc">{{$t('content.accelerating.academy.CompanyInfo.txt33')}}</span>

              <!-- 수상경력 - 추가 입력 정보 -->
              <ul
                v-if="accAwardCareerInfo.length > 1"
                class="additional-info-wrap">
                <template
                  v-for="(additionalAwardCareerInfo, index) in accAwardCareerInfo"
                >
                  <li
                    v-if="index > 0"
                    :key="index"
                    class="additional-info">
                    <BaseLabelElement
                      :label="$t('content.accelerating.academy.CompanyInfo.txt28')"
                      class="input-title"
                    />
                    <!-- 날짜 - 추가 입력 정보 -->
                    <TypeableDatepicker
                      v-model="additionalAwardCareerInfo.award_date"
                      width="half"
                      :id="'award_date' + index"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt29') + ' (YYYY-MM-DD)'"
                      initialView="year"
                    />
                    <!-- 대회명 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalAwardCareerInfo.competition_name"
                      width="half"
                      :id="'competition_name' + index"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt30')"
                    />
                    <!-- 주최기관 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalAwardCareerInfo.host_organization"
                      width="half"
                      :id="'host_organization' + index"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt31')"
                    />
                    <!-- 수상내역 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalAwardCareerInfo.award"
                      width="half"
                      :id="'award' + index"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt32')"
                    />

                    <!-- 삭제 버튼 -->
                    <BaseButton
                      type="line"
                      size="small"
                      @click="deleteAwardCareerInfo(index)"
                    >{{$t('content.common.button.btn07')}}</BaseButton>
                  </li>
                </template>
              </ul>

              <div
                v-if="accAwardCareerInfo.length < LIMIT_AWARD_CAREER_INFO"
                class="btn-wrap">
                <!-- 추가 버튼 -->
                <BaseButton
                  type="line"
                  size="medium"
                  @click="addAwardCareerInfo"
                >{{$t('content.common.button.btn18')}}</BaseButton>
              </div>
              <!-- // 수상경력 - 추가 입력 정보 -->
            </li>
            <!-- // 수상경력 -->

            <!-- 보유 지적 재산권 -->
            <li
              v-if="accApplyPgmMngDetail.accPropertyInfoYn"
              class="form-row">
              <BaseLabelElement
                :label="$t('content.accelerating.academy.CompanyInfo.txt34')"
              />
              <!-- 명칭 -->
              <BaseInputElement
                v-model="accPropertyInfo[0].property_name"
                width="half"
                id="property_name"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt35')"
              />
              <!-- 증명서번호 -->
              <BaseInputElement
                v-model="accPropertyInfo[0].property_no"
                width="half"
                id="property_no"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt36')"
              />
              <div class="input-container input-md">
                <!-- 등록 -->
                <BaseRadioButton
                  name="reg_app_code"
                  v-model="accPropertyInfo[0].reg_app_code"
                  value="0"
                >{{$t('content.accelerating.academy.CompanyInfo.txt37')}}</BaseRadioButton>
                <!-- 출원 -->
                <BaseRadioButton
                  name="reg_app_code"
                  v-model="accPropertyInfo[0].reg_app_code"
                  value="1"
                >{{$t('content.accelerating.academy.CompanyInfo.txt38')}}</BaseRadioButton>
              </div>
              <!-- 등록/출원 날짜 -->
              <TypeableDatepicker
                v-model="accPropertyInfo[0].reg_app_date"
                width="half"
                id="reg_app_date"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt39') + ' (YYYY-MM-DD)'"
                initialView="year"
              />

              <!-- 보유 지적 재산권 - 추가 입력 정보-->
              <ul
                v-if="accPropertyInfo.length > 1"
                class="additional-info-wrap">
                <template
                  v-for="(additionalPropertyInfo, index) in accPropertyInfo"
                >
                  <li
                    v-if="index > 0"
                    :key="index"
                    class="additional-info">
                    <BaseLabelElement
                      :label="$t('content.accelerating.academy.CompanyInfo.txt34')"
                      class="input-title"
                    />
                    <!-- 명칭 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalPropertyInfo.property_name"
                      width="half"
                      :id="'property_name' + index"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt35')"
                    />
                    <!-- 증명서번호 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalPropertyInfo.property_no"
                      width="half"
                      :id="'property_no' + index"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt36')"
                    />
                    <div class="input-container input-md">
                      <!-- 등록 - 추가 입력 정보 -->
                      <BaseRadioButton
                        :name="'reg_app_code' + index"
                        v-model="additionalPropertyInfo.reg_app_code"
                        value="0"
                      >{{$t('content.accelerating.academy.CompanyInfo.txt37')}}</BaseRadioButton>
                      <!-- 출원 - 추가 입력 정보 -->
                      <BaseRadioButton
                        :name="'reg_app_code' + index"
                        v-model="additionalPropertyInfo.reg_app_code"
                        value="1"
                      >{{$t('content.accelerating.academy.CompanyInfo.txt38')}}</BaseRadioButton>
                    </div>
                    <!-- 등록/출원 날짜 - 추가 입력 정보 -->
                    <TypeableDatepicker
                      v-model="additionalPropertyInfo.reg_app_date"
                      width="half"
                      :id="'reg_app_date' + index"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt39') + ' (YYYY-MM-DD)'"
                      initialView="year"
                    />

                    <!-- 삭제 버튼 -->
                    <BaseButton
                      type="line"
                      size="small"
                      @click="deletePropertyInfo(index)"
                    >{{$t('content.common.button.btn07')}}</BaseButton>
                  </li>
                </template>
              </ul>
              <!-- // 보유 지적 재산권 - 추가 입력 정보 -->

              <div
                v-if="accPropertyInfo.length < LIMIT_PROPERTY_INFO"
                class="btn-wrap">
                <!-- 추가 버튼 -->
                <BaseButton
                  type="line"
                  size="medium"
                  @click="addPropertyInfo"
                >{{$t('content.common.button.btn18')}}</BaseButton>
              </div>
            </li>
            <!-- // 보유 지적 재산권 -->

            <!-- 보유 허가 및 등록 현황 -->
            <li
              v-if="accApplyPgmMngDetail.accLicenseInfoYn"
              class="form-row">
              <BaseLabelElement
                :label="$t('content.accelerating.academy.CompanyInfo.txt40')"
              />
              <!-- 허가 명칭 -->
              <BaseInputElement
                v-model="accLicenseInfo[0].license_name"
                width="half"
                id="license_name"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt41')"
              />
              <!-- 허가 번호 -->
              <BaseInputElement
                v-model="accLicenseInfo[0].license_no"
                width="half"
                id="license_no"
                :placeholder="$t('content.accelerating.academy.CompanyInfo.txt42')"
              />

              <!-- 보유 허가 및 등록 현황 - 추가 입력 정보 -->
              <ul
                v-if="accLicenseInfo.length > 1"
                class="additional-info-wrap">
                <template
                  v-for="(additionalLicenseInfo, index) in accLicenseInfo"
                >
                  <li
                    v-if="index > 0"
                    :key="index"
                    class="additional-info">
                    <BaseLabelElement
                      :label="$t('content.accelerating.academy.CompanyInfo.txt40')"
                      class="input-title"
                    />
                    <!-- 허가 명칭 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalLicenseInfo.license_name"
                      width="half"
                      :id="'license_name' + index"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt41')"
                    />
                    <!-- 허가 번호 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalLicenseInfo.license_no"
                      width="half"
                      :id="'license_no' + index"
                      :placeholder="$t('content.accelerating.academy.CompanyInfo.txt42')"
                    />

                    <!-- 삭제 버튼 -->
                    <BaseButton
                      type="line"
                      size="small"
                      @click="deleteLicenseInfo(index)"
                    >{{$t('content.common.button.btn07')}}</BaseButton>
                  </li>
                </template>
              </ul>

              <div
                v-if="accLicenseInfo.length < LIMIT_LICENSE_INFO"
                class="btn-wrap">
                <!-- 추가 버튼 -->
                <BaseButton
                  type="line"
                  size="medium"
                  @click="addLicenseInfo"
                >{{$t('content.common.button.btn18')}}</BaseButton>
              </div>
              <!-- // 보유 허가 및 등록 현황 - 추가 입력 정보 -->
            </li>
            <!-- // 보유 허가 및 등록 현황 -->
          </ul>
        </fieldset>

        <!-- 버튼 -->
        <div class="btn-wrap type2">
          <BackButton
            @click="$emit('back')"
          />
          <BaseButton
            type="line"
            @click="$emit('save')"
          >{{$t('content.common.button.btn01')}}</BaseButton>
          <BaseButton
            @click="doNext"
          >{{$t('content.common.button.btn02')}}</BaseButton>
        </div>
        <!-- // 버튼 -->
      </form>
      <!-- // 회사정보 입력 폼 -->
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseInput from '@/components/base/BaseInput.vue';
import NumberInput from '@/components/base/NumberInput.vue';
import TelInput from '@/components/base/TelInput.vue';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInputElement from '@/components/base/BaseInputElement.vue';
import BaseRadioButton from '@/components/base/BaseRadioButton.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BackButton from '@/components/base/BackButton.vue';
import TypeableDatepicker from '@/components/base/TypeableDatepicker.vue';
import AccCeoInfoDto from '../dto/apply/AccCeoInfoDto';
import AccAwardCareerInfoDto from '../dto/apply/AccAwardCareerInfoDto';
import AccPropertyInfoDto from '../dto/apply/AccPropertyInfoDto';
import AccLicenseInfoDto from '../dto/apply/AccLicenseInfoDto';

extend('check_boxs', {
  params: ['message'],
  validate(val, { message }) {
    const checkbox1 = document.getElementById('checkbox1');
    const checkbox2 = document.getElementById('checkbox2');
    const checkbox3 = document.getElementById('checkbox3');
    if (!checkbox1.checked && !checkbox2.checked && !checkbox3.checked) {
      return message;
    }
    return true;
  },
});

extend('check_amount', {
  params: ['id', 'message'],
  validate(val, { id, message }) {
    const checkbox1 = document.getElementById('checkbox1');
    const checkbox2 = document.getElementById('checkbox2');
    const checkbox3 = document.getElementById('checkbox3');
    if (id === '1' && !checkbox1.checked) {
      return true;
    }
    if (id === '2' && !checkbox2.checked) {
      return true;
    }
    if (id === '3' && !checkbox3.checked) {
      return true;
    }
    const select = document.getElementById(`select${id}`);
    console.log(val, id, message, select.value);
    if (select.value <= 0) {
      return message;
    }
    return true;
  },
});

extend('check_member_count', {
  params: ['message'],
  validate(val, { message }) {
    const select1 = document.getElementById('select1');
    const select2 = document.getElementById('select2');
    const select3 = document.getElementById('select3');
    const checkbox1 = document.getElementById('checkbox1');
    const checkbox2 = document.getElementById('checkbox2');
    const checkbox3 = document.getElementById('checkbox3');
    let totalCount = 0;
    if (checkbox1.checked) {
      totalCount += select1.value * 1;
    }
    if (checkbox2.checked) {
      totalCount += select2.value * 1;
    }
    if (checkbox3.checked) {
      totalCount += select3.value * 1;
    }
    console.log(totalCount);
    if (totalCount !== 4) {
      return message;
    }
    return true;
  },
});

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 (탭) - 1단계 : 회사정보
 */
export default {
  name: 'ProgramsApplyStep1',
  mixins: [mixinHelperUtils],
  components: {
    ValidationObserver, // https://logaretm.github.io/vee-validate/api/validation-observer.html
    ValidationProvider, // https://logaretm.github.io/vee-validate/api/validation-provider.html
    BaseInput,
    NumberInput,
    TelInput,
    BaseLabelElement,
    BaseInputElement,
    BaseRadioButton,
    BaseButton,
    BackButton,
    TypeableDatepicker,
    BaseCheckbox,
  },

  props: {
    /**
     * Acc지원프로그램 관리 상세
     * @type {import('../dto/AccApplyPgmMngDetailDto').default} AccApplyPgmMngDetailDto
     */
    accApplyPgmMngDetail: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 지원정보 (회사정보, 서비스, 기타입력 등)
     * @type {import('../dto/apply/AccInfoDto').default} AccInfoDto
     * @see AccApplyDto
     */
    accInfo: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 대표자, 공동대표
     * @type {AccCeoInfoDto[]}
     * @see AccApplyDto
     */
    accCeoInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 수상경력
     * @type {AccAwardCareerInfoDto[]}
     * @see AccApplyDto
     */
    accAwardCareerInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 보유 지적 재산권
     * @type {AccPropertyInfoDto[]}
     * @see AccApplyDto
     */
    accPropertyInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 보유 허가 및 등록 현황
     * @type {AccLicenseInfoDto[]}
     * @see AccApplyDto
     */
    accLicenseInfo: {
      type: Array,
      required: true,
    },
  },

  data() {
    /**
     * 대표자 연락처 : accInfo.ceo_cellphone_first, accInfo.ceo_cellphone_sec를 한번에 입력하도록 변환
     */
    let adjustedCeoCellphone;
    if (this.accInfo.ceo_cellphone_first && this.accInfo.ceo_cellphone_sec) {
      adjustedCeoCellphone = `${this.accInfo.ceo_cellphone_first}${this.accInfo.ceo_cellphone_sec}`;
    }

    /**
     * 설립연도 : accInfo.build_year, accInfo.build_month, accInfo.build_day를 한번에 입력하도록 변환
     */
    let adjustedBuildDate;
    if (this.accInfo.build_year && this.accInfo.build_month && this.accInfo.build_day) {
      adjustedBuildDate = `${this.accInfo.build_year}-${this.accInfo.build_month}-${this.accInfo.build_day}`;
    }

    return {
      /**
       * 대표자 연락처
       * @type {string}
       */
      ceoCellphone: adjustedCeoCellphone,
      /**
       * 설립연도
       * @type {string}
       */
      buildDate: adjustedBuildDate,

      /**
       * 대표자 입력 개수 제한
       * @type {number}
       */
      LIMIT_CEO_INFO: 3,
      /**
       * 수상경력 입력 개수 제한
       * @type {number}
       */
      LIMIT_AWARD_CAREER_INFO: 5,
      /**
       * 보유 지적 재산권 입력 개수 제한
       * @type {number}
       */
      LIMIT_PROPERTY_INFO: 5,
      /**
       * 보유 허가 및 등록 현황 입력 개수 제한
       * @type {number}
       */
      LIMIT_LICENSE_INFO: 3,
      /**
       * 멤버 숫자 옵션
       */
      memberCount: [
        {
          text: '선택해 주세요.',
          value: 0,
        },
        {
          text: '1명',
          value: 1,
        },
        {
          text: '2명',
          value: 2,
        },
        {
          text: '3명',
          value: 3,
        },
        {
          text: '4명',
          value: 4,
        },
      ],
      checkAll: false,
      checkMember1: this.accInfo.check_developer,
      checkMember2: this.accInfo.check_director,
      checkMember3: this.accInfo.check_designer,
    };
  },

  watch: {
    /**
     * 대표자 연락처 : ceoCellphone 입력값을 accInfo.ceo_cellphone_first, accInfo.ceo_cellphone_sec로 분리
     */
    ceoCellphone(newVal, oldVal) {
      if (newVal !== oldVal) {
        const [first, second, third] = newVal.split('-');
        this.accInfo.ceo_cellphone_first = first;
        this.accInfo.ceo_cellphone_sec = `${(second || '')}${(third || '')}`;
      }
    },
    /**
     * 설립연도 : buildDate 입력값을 accInfo.build_year, accInfo.build_month, accInfo.build_day로 분리
     */
    buildDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        const [first, second, third] = newVal.split('-');
        this.accInfo.build_year = first;
        this.accInfo.build_month = second;
        this.accInfo.build_day = third;
      }
    },
    checkMember1(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.checkAll = this.checkMember1 || this.checkMember2 || this.checkMember3;
      this.accInfo.check_developer = newVal;
    },
    checkMember2(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.checkAll = this.checkMember1 || this.checkMember2 || this.checkMember3;
      this.accInfo.check_director = newVal;
    },
    checkMember3(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.checkAll = this.checkMember1 || this.checkMember2 || this.checkMember3;
      this.accInfo.check_designer = newVal;
    },
  },

  /**
   * beforeRouteEnter
   * @see {@link https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards In-Component Guards}
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.accApplyPgmMngDetail.isEmptyStep1()) {
        // 입력할 것이 없으면 스텝 건너뛰기
        if (from && from.name === 'ProgramsApplyStep2') {
          // vm.doPrev();
          vm.doNext();
        } else {
          vm.doNext();
        }
      }
    });
  },

  methods: {
    doNext() {
      this.validateForm(this.$refs.form) // mixinHelperUtils
        .then(() => {
          this.$router.push({
            name: 'ProgramsApplyStep2',
          });
        });
    },

    /**
     * 대표자 - 추가 입력 정보 (공동대표) 추가
     */
    addCeoInfo() {
      if (this.accCeoInfo.length < this.LIMIT_CEO_INFO) {
        this.accCeoInfo.push(new AccCeoInfoDto({}));
      }
    },
    /**
     * 대표자 - 추가 입력 정보 (공동대표) 삭제
     */
    deleteCeoInfo(index) {
      if (index < this.LIMIT_CEO_INFO) {
        this.accCeoInfo.splice(index, 1);
      }
    },

    /**
     * 수상경력 - 추가 입력 정보 추가
     */
    addAwardCareerInfo() {
      if (this.accAwardCareerInfo.length < this.LIMIT_AWARD_CAREER_INFO) {
        this.accAwardCareerInfo.push(new AccAwardCareerInfoDto({}));
      }
    },
    /**
     * 수상경력 - 추가 입력 정보 삭제
     */
    deleteAwardCareerInfo(index) {
      if (index < this.LIMIT_AWARD_CAREER_INFO) {
        this.accAwardCareerInfo.splice(index, 1);
      }
    },

    /**
     * 보유 지적 재산권 - 추가 입력 정보 추가
     */
    addPropertyInfo() {
      if (this.accPropertyInfo.length < this.LIMIT_PROPERTY_INFO) {
        this.accPropertyInfo.push(new AccPropertyInfoDto({}));
      }
    },
    /**
     * 보유 지적 재산권 - 추가 입력 정보 삭제
     */
    deletePropertyInfo(index) {
      if (index < this.LIMIT_PROPERTY_INFO) {
        this.accPropertyInfo.splice(index, 1);
      }
    },

    /**
     * 보유 허가 및 등록 현황 - 추가 입력 정보 추가
     */
    addLicenseInfo() {
      if (this.accLicenseInfo.length < this.LIMIT_LICENSE_INFO) {
        this.accLicenseInfo.push(new AccLicenseInfoDto({}));
      }
    },
    /**
     * 보유 허가 및 등록 현황 - 추가 입력 정보 삭제
     */
    deleteLicenseInfo(index) {
      if (index < this.LIMIT_LICENSE_INFO) {
        this.accLicenseInfo.splice(index, 1);
      }
    },
  },
};
</script>
