<template>
  <div>
    <BaseLabelElement
      v-if="label"
      :label="label"
      :id="id"
      :required="required"
      :class="labelClass"
    />
    <BaseInputElement
      :id="id"
      :type="type"
      :icon="icon"
      ref="input"
      v-bind="$attrs"
      :value="value"
      @input="onInput"
      :inputOptions="inputOptions"
      :invalid="invalid"
    />
  </div>
</template>

<script>
import BaseLabelElement from './BaseLabelElement.vue';
import BaseInputElement from './BaseInputElement.vue';

/**
 * 기본 Input 컴포넌트
 * - ([기본 Input 요소 컴포넌트](/#baseinputelement)와 [기본 Label 요소 컴포넌트](/#baselabelelement)가 조합된) 단일 Input
 */
export default {
  name: 'BaseInput',

  components: {
    BaseLabelElement,
    BaseInputElement,
  },

  /**
   * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
   */
  inheritAttrs: false,

  props: {
    /**
     * 타입
     * @values text, currency, number, tel
     * @see BaseInputElement
     */
    type: {
      type: String,
      default: 'text',
      validator: (value) => [
        'text',
        'currency',
        'number',
        'tel',
      ].indexOf(value) !== -1,
    },

    /**
     * 입력값
     * @model
     */
    value: {
      type: [String, Number],
    },

    /**
     * 레이블
     */
    label: {
      type: String,
    },

    /**
     * 레이블 스타일 클래스
     */
    labelClass: {
      type: String,
    },

    /**
     * ID
     */
    id: {
      type: String,
      default() {
        // https://stackoverflow.com/a/12502559
        return `input-${Math.random().toString(36).slice(2)}`;
      },
    },

    /**
     * 아이콘
     * @values none, homepage, sns, media
     * @see BaseInputElement
     */
    icon: {
      type: String,
      default: 'none',
      validator: (value) => [
        'none',
        'homepage',
        'sns',
        'media',
      ].indexOf(value) !== -1,
    },

    /**
     * 필수 여부
     */
    required: {
      type: Boolean,
      default: false,
    },

    /**
     * 유효성 검사 실패 여부
     */
    invalid: {
      type: Boolean,
      default: false,
    },

    /**
     * 입력 옵션
     * @see BaseInputElement
     */
    inputOptions: {
      type: Object,
    },
  },

  methods: {
    onInput(value) {
      /**
       * 입력 이벤트
       * @event input
       * @property {string|number} 변경된 입력값
       */
      this.$emit('input', value);
    },
  },
};
</script>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<BaseInput value="가나다ABC" placeholder="입력해주세요..." />
<BaseInput value="" placeholder="입력불가" disabled />
```

레이블(label), 필수 여부(required), 유효성 검사 실패 여부(invalid) 사용 예시 :
```jsx
<BaseInput id="subject34" label="제목" value="일일보고" required />

<br />

<BaseInput id="subject35" label="내용" value="" required invalid />
<span class="input-desc">간략히 개조식으로 입력해 주세요.</span>
<span class="input-error-desc">내용을 입력해 주세요.</span>
```
</docs>
