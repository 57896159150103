/**
 * Acc지원 정보 - 대표자, 공동대표 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
 */
export default class AccCeoInfoDto {
  constructor(obj) {
    this.acc_ceo_no = obj.acc_ceo_no;
    this.acc_no = obj.acc_no;
    this.ceo_name = obj.ceo_name;
    this.display_order = obj.display_order || -1;
  }
}
