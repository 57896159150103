<template>
  <div>
    <BaseLabelElement
      v-if="label"
      :label="label"
      :id="id"
      :required="required"
    />
    <BaseInputElement
      :id="id"
      type="tel"
      v-bind="$attrs"
      :value="value"
      @input="onInput"
      :inputOptions="{ raw: raw }"
      :invalid="invalid"
    />
  </div>
</template>

<script>
import BaseInput from './BaseInput.vue';

/**
 * 전화번호 입력용 Input 컴포넌트
 * (국내 전화번호 기준)
 */
export default {
  name: 'TelInput',
  extends: BaseInput, // (확장) 기본 Input 컴포넌트
  props: {
    /**
     * 원래 입력값(타입) 유지 여부
     */
    raw: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<TelInput value="025201500" />
```

레이블 사용 예시 :
```jsx
<TelInput label="대표전화" value="1588-6363" required />
```
</docs>
